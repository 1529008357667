import React, { useState, useEffect } from "react";
import {
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import profileimagedummy from "../../../assets/max-learn-img/default-profile.png";
import { useHistory, useLocation } from "react-router";

const NavbarUser = (props, profilepic) => {
  const history = useHistory();
  const logoutClick = () => {
    localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("applicationId");
    localStorage.removeItem("fullname");
    localStorage.removeItem("userid");
    localStorage.removeItem("learnerID");
  };

  const profileredirect = () => {
    history.push({
      pathname: `/profileview`,
      state: [localStorage.getItem("userid")],
    });
  };
  const UserDropdown = () => {
    return (
      <DropdownMenu right>
        <DropdownItem tag="a" className="tabs_drop" onClick={profileredirect}>
          <span className="align-middle">Edit Profile</span>
        </DropdownItem>
        {/* <DropdownItem tag="a" href="#">
          <span className="align-middle">My Inbox</span>
        </DropdownItem> */}

        <DropdownItem divider />
        <DropdownItem
          tag="a"
          className="tabs_drop"
          href="/"
          onClick={logoutClick}
        >
          <span className="align-middle">Log Out</span>
        </DropdownItem>
      </DropdownMenu>
    );
  };
  return (
    <>
      <div>
        <ul className="nav navbar-nav navbar-nav-user float-right">
          <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle tag="a" className="nav-link dropdown-user-link">
              <div className="user-nav d-sm-flex d-none">
                <span
                  className="user-name text-bold-600 user_login txt_userns role_strta"
                  title={localStorage.getItem("fullname")}
                >
                  {props.userName}
                </span>
                <span className="role_txtc">
                  {localStorage.getItem("role-title")}
                </span>
              </div>
              <span data-tour="user">
                <img
                  src={
                    localStorage.getItem("profilepic")
                      ? localStorage.getItem("profilepic")
                      : profileimagedummy
                  }
                  className="round"
                  height="40"
                  width="40"
                  alt="avatar"
                />
              </span>
            </DropdownToggle>
            <UserDropdown />
          </UncontrolledDropdown>
        </ul>
      </div>
    </>
  );
};
export default NavbarUser;
