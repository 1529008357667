const initialState = {
    list: [],
    total: "",
    searchKey: "",
  };
  
  export const ManageMembersListReducer = (state = initialState, action) => {
    
    switch (action.type) {
      case "MANAGE_ALL_SQUADS_MEMBER_RESPONSE":
        return {
          ...state,
          list: [...action.data.list],
          total: action.data.total,
          rulesetDetails:action.data.rulesetDetails
        };
  
      default:
        return state;
    }
  };
  
