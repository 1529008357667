const initialState = {
  learners: [],
};

const learnersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_LEARNERS":
      const data = {
        ...state,
        learners: [...action.payload.learners],
        count: action.payload.count,
      };
      return data;
    default:
      return state;
  }
};

export default learnersReducer;
