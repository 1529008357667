const initialState = {
  topics: [],
};

const topicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_TOPICS":
      const data = {
        ...state,
        topics: [...action.payload.topics],
        count: action.payload.count,
      };
      return data;
    default:
      return state;
  }
};

export default topicsReducer;
