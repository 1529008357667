const initialState = {
    list: [],
    total: "",
    searchKey: "",
  };
  
  const ManagePermissionsListReducer = (state = initialState, action) => {
    switch (action.type) {
      case "MANAGE_PERMISSIONS_RESPONSE":
        return {
          ...state,
          list: [...action.data.list],
          total: action.data.total,
        };
  
      default:
        return state;
    }
  };
  
  export default ManagePermissionsListReducer;
  