const initialState = {
  list: [],
  total:"",
  searchKey: "",
};

const ManageSubjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case "MANAGE_ALL_SUBJECT_RESPONSE":
      return {
        ...state,
        list: [...action.data.list],
        total: action.data.total,
      };

    default:
      return state;
  }
};

export default ManageSubjectReducer;
