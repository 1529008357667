const initialState = {
  list: [],
  total: "",
  searchKey: "",
};

const ManageLeaderboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "MANAGE_ALL_INDIVIDUAL_RESPONSE":
      return {
        ...state,
        list: [...action.data.list],
        total: action.data.total,
      };

    case "MANAGE_ALL_SQUAD_RESPONSE":
      return {
        ...state,
        list: [...action.data.list],
        total: action.data.total,
      };

    default:
      return state;
  }
};

export default ManageLeaderboardReducer;
