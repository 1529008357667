import React from "react";
import * as Icon from "react-feather";
let user = "super-admin";
let permissions = [];
let currentUser = localStorage.getItem("user-role");
permissions = localStorage.getItem("role");
const data = JSON.parse(permissions);
let permissionsData = [];
permissionsData = data;
var storedDataFeatures = localStorage.getItem("features");
var parsedDataFeatures = JSON.parse(storedDataFeatures);
console.log(parsedDataFeatures, "parsedDataFeatures");

let squadAccess = [];
let challengeAccess = [];
let userManagementAccess = [];
let adminManagementAccess = [];
let reportAccess = [];

let squadAccessControl = [];
let challengeAccessControl = [];
let userManagementAccessControl = [];
let adminManagementAccessControl = [];
let reportAccessControl = [];

if (data === null) {
  permissionsData = [];
}
let permissionsDataManagement = [];
let permissionContentAccessControl = [];

if (permissionsData.length !== 0) {
  permissionsDataManagement = permissionsData.filter((el) => {
    if (el._id === currentUser) {
      if (el.contentAccess === true) {
        return el._id;
      }
    }
  });
  challengeAccess = permissionsData.filter((el) => {
    if (el._id === currentUser) {
      if (el.challengeAccess === true) {
        return el._id;
      }
    }
  });
  squadAccess = permissionsData.filter((el) => {
    if (el._id === currentUser) {
      if (el.squadAccess === true) {
        return el._id;
      }
    }
  });
  userManagementAccess = permissionsData.filter((el) => {
    if (el._id === currentUser) {
      if (el.userManagementAccess === true) {
        return el._id;
      }
    }
  });
  adminManagementAccess = permissionsData.filter((el) => {
    if (el._id === currentUser) {
      if (el.adminManagementAccess === true) {
        return el._id;
      }
    }
  });
  reportAccess = permissionsData.filter((el) => {
    if (el._id === currentUser) {
      if (el.reportAccess === true) {
        return el._id;
      }
    }
  });
  challengeAccessControl = challengeAccess.map((el) => el._id);

  permissionContentAccessControl = permissionsDataManagement.map(
    (el) => el._id
  );
  challengeAccessControl = challengeAccess.map((el) => el._id);
  squadAccessControl = squadAccess.map((el) => el._id);

  userManagementAccessControl = userManagementAccess.map((el) => el._id);
  adminManagementAccessControl = adminManagementAccess.map((el) => el._id);
  reportAccessControl = reportAccess.map((el) => el._id);
}

// const permissionChallengeAccess = permissionsData.map((el) => {
//   if (el._id === currentUser) {
//     if (el.challengeAccess === true) {
//       return el.roleTitle;
//     } else {
//       return undefined;
//     }
//   }
// });

// const permissionContentAccess = permissionsData.map((el) => {
//   if (el._id === currentUser) {
//     if (el.contentAccess === true) {
//       return el.roleTitle;
//     } else {
//       return undefined;
//     }
//   }
// });

// const permissionReportAccess = permissionsData.map((el) => {
//   if (el._id === currentUser) {
//     if (el.reportAccess === true) {
//       return el.roleTitle;
//     } else {
//       return undefined;
//     }
//   }
// });

// const permissionSquadAccess = permissionsData.map((el) => {
//   if (el._id === currentUser) {
//     if (el.squadAccess === true) {
//       return el.roleTitle;
//     } else {
//       return undefined;
//     }
//   }
// });

// const permissionUserManagementAccess = permissionsData.map((el) => {
//   if (el._id === currentUser) {
//     if (el.userManagementAccess === true) {
//       return el.roleTitle;
//     } else {
//       return undefined;
//     }
//   }
// });

const navigationConfig = [
  // {
  //   id: "dashboard",
  //   title: "Dashboard",
  //   type: "collapse",
  //   icon: <Icon.Home size={20} />,
  //   badge: "warning",
  //   badgeText: "2",
  //   children: [
  //     {
  //       id: "analyticsDash",
  //       title: "Analytics",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/",
  //     },
  //   ],
  // },
  // {
  //   id: "dashboard",
  //   title: "Dashboard",
  //   type: "item",
  //   icon: <Icon.Grid size={20} />,
  //   permissions: permissionContentAccess,
  //   navLink: "/dashboard",
  // },
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Grid size={20} />,
    permissions: permissionsData.length ? [currentUser] : [currentUser],
    navLink: "/dashboard",
  },
  {
    id: "creatordashboard",
    title: "Creator’s Dashboard",
    type: "item",
    icon: <Icon.Grid size={20} />,
    permissions: permissionsData.length ? [currentUser] : [currentUser],
    navLink: "/creator-dashboard",
  },
  {
    id: "Leaderboards",
    title: "Leaderboards",
    type: "collapse",
    icon: <Icon.Grid size={20} />,
    permissions: permissionsData.length ? [currentUser] : [currentUser],
    //permissions:permissionContentAccess,
    children: [
      {
        id: "individual",
        title: "Individuals",
        type: "item",
        // icon: <Icon.Circle size={12} />,
        //permissions: permissionContentAccess,
        navLink: "/individuals",
        // activeLink: "/data-list/category",
      },
      {
        id: "squad",
        title: "Squads",
        type: "item",
        // icon: <Icon.Circle size={12} />,
        navLink: "/leaderboardSquads",
        //activeLink: "/leaderboardSquads?pageNum=1",
      },
    ],
  },
  {
    id: "reports",
    title: "Reports",
    type: "collapse",
    icon: <Icon.Grid size={20} />,
    content: "permissions",
    permissions: reportAccessControl,
    children: [
      {
        id: "Overview",
        title: "Overview",
        type: "item",
        navLink: "/overview",
        permissions: reportAccessControl,
      },
      {
        id: "uasageanalytics",
        title: "Usage Analytics",
        type: "item",
        navLink: "/usage_analytics",
        permissions: reportAccessControl,
      },
      {
        id: "learners",
        title: "Learners",
        type: "item",
        navLink: "/report/learners",
        permissions: reportAccessControl,
      },
      {
        id: "reporttopic",
        title: "Topics",
        type: "item",
        navLink: "/report/topics",
        permissions: reportAccessControl,
      },
      {
        id: "ChallengesReports",
        title: "Challenges",
        type: "item",
        navLink: "/report/challenges",
        permissions:
          parsedDataFeatures && parsedDataFeatures.challenges
            ? parsedDataFeatures.challenges === true
              ? reportAccessControl
              : ""
            : "",
      },
      {
        id: "CertsReports",
        title: "Certs",
        type: "item",
        navLink: "/report/certs",
        permissions:
          parsedDataFeatures && parsedDataFeatures.certs
            ? parsedDataFeatures.certs === true
              ? reportAccessControl
              : ""
            : "",
      },
      {
        id: "LearQuestsReports",
        title: "Learn Quests",
        type: "item",
        navLink: "/report/quests",
        permissions:
          parsedDataFeatures && parsedDataFeatures.learnQuest
            ? parsedDataFeatures.learnQuest === true
              ? reportAccessControl
              : ""
            : "",
      },
    ],
  },

  {
    type: "groupHeader",
    groupTitle: "CONTENTS",
  },
  {
    id: "managecontent",
    title: "Manage Content",
    type: "collapse",
    icon: <Icon.Grid size={20} />,
    content: "permissions",
    // permissions: permissionContentAccess,
    permissions: permissionContentAccessControl,
    children: [
      // {
      //   id: "listView",
      //   title: "List View",
      //   type: "item",
      //   icon: <Icon.Circle size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/data-list/list-view",
      // },
      {
        id: "category",
        title: "Categories",
        type: "item",
        //icon: <Icon.Circle size={12} />,
        permissions: permissionContentAccessControl,
        navLink: "/data-list/category",
        className: "ml-5",
        // activeLink: "/data-list/category",
      },
      {
        id: "subjects",
        title: "Subjects",
        type: "item",
        // icon: <Icon.Circle size={12} />,
        permissions: permissionContentAccessControl,
        navLink: "/data-list/subject",
      },
      {
        id: "topic",
        title: "Topics",
        type: "item",
        // icon: <Icon.Circle size={12} />,
        permissions: permissionContentAccessControl,
        navLink: "/data-list/topics",
      },

      {
        id: "decks",
        title: "Decks",
        type: "item",
        // icon: <Icon.Circle size={12} />,
        permissions: permissionContentAccessControl,
        navLink: "/data-list/decks",
        // activeLink: "/data-list/decks?PageNum=1",
      },
      {
        id: "KLP",
        title: "Key Learning Points",
        type: "item",
        // icon: <Icon.Circle size={12} />,
        permissions: permissionContentAccessControl,
        navLink: "/data-list/klp",
      },
      {
        id: "question",
        title: "Questions",
        type: "item",
        // icon: <Icon.Circle size={12} />,
        permissions: permissionContentAccessControl,
        navLink: "/data-list/questions",
      },
      {
        id: "ai-generation",
        title: "AI Generation",
        type: "item",
        // icon: <Icon.Circle size={12} />,
        permissions: permissionContentAccessControl,
        navLink: "/data-list/ai-generation",
      },
    ],
  },
  {
    id: "managelearnings",
    title: "Manage Learnings",
    type: "collapse",
    icon: <Icon.Grid size={20} />,
    content: "permissions",
    permissions: challengeAccessControl,
    children: [
      {
        id: "challengs",
        title: "Challenges",
        type: "item",
        //  icon: <Icon.Circle size={12} />,
        navLink: "/data-list/challengs",
        permissions:
          parsedDataFeatures && parsedDataFeatures.challenges
            ? parsedDataFeatures.challenges === true
              ? challengeAccessControl
              : ""
            : "",
      },
      {
        id: "Certs",
        title: "Certs",
        type: "item",
        // icon: <Icon.Circle size={12} />,
        navLink: "/data-list/certs",
        permissions:
          parsedDataFeatures && parsedDataFeatures.certs
            ? parsedDataFeatures.certs === true
              ? [currentUser]
              : false
            : "",
      },
      {
        id: "learnquests",
        title: "Learn Quests",
        type: "item",
        //  icon: <Icon.Circle size={12} />,
        navLink: "/data-list/learn-quests",
        permissions:
          parsedDataFeatures && parsedDataFeatures.learnQuest
            ? parsedDataFeatures.learnQuest === true
              ? [currentUser]
              : false
            : "",
      },
      // {
      //   id: "Goals",
      //   title: "Goals",
      //   type: "item",
      //   //  icon: <Icon.Circle size={12} />,
      //   navLink: "/goals",
      // },
    ],
  },
  {
    id: "Feedbacks",
    title: "Manage Feedbacks",
    icon: <Icon.Grid size={20} />,
    type: "item",
    //permissions: permissionManagementAccess,
    navLink: "/feedbacks",
    permissions: [currentUser],
  },
  {
    id: "cstUpload",
    title: "CST Upload",
    type: "item",
    icon: <Icon.Grid size={20} />,
    permissions: permissionsData.length ? [currentUser] : [currentUser],
    navLink: "/cstupload",
  },
  {
    type: "groupHeader",
    groupTitle: "USERS",
  },
  {
    id: "DepartmentMaster",
    title: "Departments",
    icon: <Icon.Grid size={20} />,
    type: "item",
    permissions: permissionsData.length ? [currentUser] : [currentUser],
    navLink: "/departments",
  },
  {
    id: "JobTitle",
    title: "Job Title",
    icon: <Icon.Grid size={20} />,
    type: "item",
    permissions: permissionsData.length ? [currentUser] : [currentUser],
    navLink: "/jobtitle",
  },
  {
    id: "Learners",
    title: "Manage Learners",
    icon: <Icon.Grid size={20} />,
    type: "item",
    content: "permissions",
    permissions: userManagementAccessControl,
    navLink: "/data-list/learners",
  },
  {
    id: "Squads",
    title: "Manage Squads",
    icon: <Icon.Grid size={20} />,
    type: "item",
    content: "permissions",
    permissions: squadAccessControl,
    navLink: "/data-list/squadslist",
  },
  {
    id: "learnerRole",
    title: "Learner Upload",
    type: "item",
    icon: <Icon.Grid size={20} />,
    permissions: permissionsData.length ? [currentUser] : [currentUser],
    navLink: "/learnerroleupload",
  },
  {
    id: "departmentRole",
    title: "Department Upload",
    type: "item",
    icon: <Icon.Grid size={20} />,
    permissions: permissionsData.length ? [currentUser] : [currentUser],
    navLink: "/departmentroleupload",
  },
  {
    id: "manageusers",
    title: "Manage Users's Role",
    type: "collapse",
    icon: <Icon.Grid size={20} />,
    content: "permissions",
    permissions: adminManagementAccessControl,
    children: [
      {
        id: "users",
        title: "User's Roles",
        type: "item",
        // icon: <Icon.Circle size={12} />,
        navLink: "/data-list/userslist",
        permissions: adminManagementAccessControl,
      },
      {
        id: "permission",
        title: "Permission Setting",
        type: "item",
        //  icon: <Icon.Circle size={12} />,
        navLink: "/data-list/permission-settings",
        permissions: adminManagementAccessControl,
      },
    ],
  },
  {
    id: "helpAndSupport",
    title: "Help & Support",
    icon: <Icon.Grid size={20} />,
    type: "item",
    permissions: [currentUser],
    navLink: "/data-list/help-and-support",
  },
  {
    id: "didYouKnow",
    title: "Did you Know",
    icon: <Icon.Grid size={20} />,
    type: "item",
    permissions: [currentUser],
    navLink: "/data-list/did-you-know",
  },
  {
    id: "customNotification",
    title: "Custom Notification",
    icon: <Icon.Grid size={20} />,
    type: "item",
    permissions: [currentUser],
    navLink: "/data-list/custom-notification",
  },
  // {
  //   type: "groupHeader",
  //   groupTitle: "EXTENSIONS",
  // },
  // {
  //   id: "extPagination",
  //   title: "Pagination",
  //   icon: <Icon.MoreHorizontal size={20} />,
  //   type: "item",
  //   permissions: ["admin", "editor"],
  //   navLink: "/extensions/pagination",
  // },
];

export default navigationConfig;
